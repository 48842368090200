import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import ExhibitionHero from "../components/ExhibitionHero";
import Seo from "../components/Seo";

import exhibitionLine from "../assets/lines/another-line.svg";

const Collection = ({ data }) => {
  const { nodes } = data.allContentfulCollection;

  let exhibitionContent = nodes.map((collection) => {
    return <ExhibitionHero collection={collection} key={collection.title} />;
  });

  return (
    <Layout>
      <Seo title={`Exhibition • KSA Foundation 2021`} />

      <div className="collectionBody exhibition">
        <div className="exhibitionContainer">
          <img
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
              top: "50%",
              zIndex: "-9",
              width: "100%",
              height: "100%",
              userSelect: "none",
            }}
            src={exhibitionLine}
            alt=""
          ></img>
          {exhibitionContent}
        </div>
      </div>
    </Layout>
  );
};

export default Collection;

export const pageQuery = graphql`
  query {
    allContentfulCollection {
      nodes {
        id
        title
        heroImage {
          gatsbyImageData
          title
        }
        portraitImage {
          gatsbyImageData
          title
        }
        student {
          studentName
          slug
          projectName
          heroImage {
            gatsbyImageData
            id
            title
          }
        }
      }
    }
  }
`;
