import React from "react";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// Utilities
import kebabCase from "lodash/kebabCase";

export default function ExhibitionHero({ collection }) {
  return (
    <>
      <div className="exhibitionHero">
        <Link
          key={collection.title}
          to={`/exhibition/${kebabCase(collection.title)}/`}
        >
          {collection.heroImage && (
            <GatsbyImage
              loading="eager"
              objectFit="scale-down"
              placeholder="blurred"
              image={getImage(collection.heroImage)}
              alt={
                collection.heroImage.title
                  ? collection.heroImage.title
                  : `Kingston Foundation 2021 collection`
              }
            />
          )}

          <div className="exhibitionHeroOverlay">
            <p className="exhibitionTitle">{collection.title}</p>
          </div>
        </Link>
      </div>
    </>
  );
}
